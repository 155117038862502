import React from 'react';
import awslink from '../aws/awslink.json';
class PatientAPI extends React.Component {

    setCookie(cname, cvalue, secs) {
        var d = new Date();
        d.setTime(d.getTime() + (secs * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
        return ''
    }

    check_cognito_callback() {
        // checks if we arrived here from Cognito, and if so stores the ID token
        // for 24 hours as a cookie, and replaces the URL to stop accidental sharing
        var url = window.location.href
        if (url.includes('id_token')) {
            this.setCookie('id_token', url.split('id_token=')[1].split('&')[0], 24 * 60 * 60)
            window.location.href = '/App'
        }
        // var id_token = this.getCookie('id_token')
    }

    check_patient_param() {
        // checks if patient_id was passed in the URL, store this as a cookie
        // whenever the Patient page is loaded, then use the cookied patient_id to
        // display patient data
        var url = window.location.href
        if (url.includes('patient_id')) {
            this.setCookie('patient_id', url.split('patient_id=')[1].split('&')[0], 24 * 60 * 60)
        }
    }

    get_current_patient_id() {
        return this.getCookie('patient_id');
    }

    async cognito_ensure_login() {
        //check if the users is signed in by calling the patient API
        console.log('CHECKING COGNITO')
        var cognito_result = await this.patient_api('test=1') === false ? false : true;
        console.log(cognito_result);
        if (cognito_result === false) {

            console.log('User is not signed in')
            this.setState({ signin: true });
            var sign_in_url;
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                // dev code
                sign_in_url = awslink.cognito_url + "login?client_id=" + awslink.client_id + "&response_type=token&scope=aws.cognito.signin.user.admin+openid&redirect_uri=http://localhost:3000/App"
            } else {
                // production code
                sign_in_url = awslink.cognito_url + "login?client_id=" + awslink.client_id + "&response_type=token&scope=aws.cognito.signin.user.admin+openid&redirect_uri=https://" + awslink.cloudfront_url + "/App"

            }
            console.log(sign_in_url)
            window.location.replace(sign_in_url);
        }
        console.log(cognito_result)
        return cognito_result;
    }

    async patient_api(params) {
        //rewrite the page if not logged in and then send the user to sign in 
        //attempt to call the patient API GW - if it fails for whatever reason then assume not logged in
        var url = `${awslink.api_patient}/patient?${params}`
        var auth_header = "Bearer " + this.getCookie('id_token')
        const fetch_resp = await fetch(url, { method: 'get', headers: new Headers({ 'Authorization': auth_header }) }).then(response => {
            console.log(response.status)
            if (response.status >= 400 && response.status < 600) {
                console.log('error from fetch')
                throw new Error(response.status);
            }
            return response;
        }).catch((error) => {
            console.log(error)
            if (error === 409) {
                return error;
            }
            return false;

        });
        if (fetch_resp === false) {
            return false
        }
        console.log(fetch_resp)
        return await fetch_resp.json();
    }

    render() {
        return (
            <div></div>
        )
    }

}

export default PatientAPI;

