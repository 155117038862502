import React, { useState, createContext, useEffect } from "react";
import PatientAPI from '../aws/PatientAPI.js'

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import Table from "../components/Table/Table.js";
import axios from "axios";
import Slider from "@material-ui/core/Slider";
import Cookies from 'universal-cookie'
//Generic code for a RYAH results table when answers are presented in a table (e.g. weekly and daily responses)

// class RyahTable extends React.Component {
const RyahStats = (props) => {
    // constructor(props) {
    //     super(props)
    //     this.ts_min = 0;
    //     this.ts_max = Number.MAX_VALUE;
    //     this.patientapi = React.createRef();
    //     this.state = {
    //         patient_id: 0,
    //         patient_data: null,
    //         patient_fetched: false,
    //         card_expanded: 'inline',
    //         questions: null,
    //         tableData: [],
    //         tableHeader: [],
    //         range: ''
    //     };
    //     this.tableData = []
    //     this.tableHeader = []
    //     this.toggle = this.toggle.bind(this);
    //     this.createTable = this.createTable.bind(this);
    //     // this.tableData = [];
    //     // this.tableHeader = [];
    // }
    const [url, setUrl] = useState('')
    const [auth, setAuth] = useState('')
    const [questions, setQuestions] = useState([])
    const [ts_min, setTsMin] = useState(0)
    const [ts_max, setTsMax] = useState(Number.MAX_VALUE)
    const [tableHeader, setTableHeader] = useState([])
    const [tableData, setTableData] = useState([])
    const [cardExpanded, setCardExpanded] = useState('inline')
    const [patientData, setPatientData] = useState({})
    const [totalSessions, setTotalSessions] = useState(-999)

    // for stats
    const [window, setWindow] = useState(28)
    const [date, setDate] = useState('')

    useEffect(() => {
        console.log(props)
        createTable();
    }, [props.data]);
    useEffect(() => {
        console.log(props)
        const cookies = new Cookies()
        const x = cookies.get('historyDays')
        if (x) {
            setWindow(x)
        }
    }, []);

    useEffect(() => {
        const past = Date.now() - 1000 * 60 * 60 * 24 * window
        let windowDate = new Date(past)
        windowDate.setHours(0, 0, 0)
        setDate(windowDate.toISOString().split('T')[0])

        // console.log(windowDate.getTime())
        // console.log(props.data.tableData)
        if (props.data && props.data.tableData) {
            let count = 0
            // console.log(props.data.tableData)
            for (var a in props.data.tableData) {
                // console.log(a)
                if (props.data.tableData[a].timestamp > windowDate.getTime()) {
                    count += 1
                }
            }
            setTotalSessions(count)
            const cookies = new Cookies()
            cookies.set('historyDays', window, { path: '/' })
        }


    }, [window, props])


    const clean_date = async (d) => {
        // return d.toString().replace('GMT+0000 (Greenwich Mean Time)', '').replace('GMT+0100 (Greenwich Mean Time)', '')
        var dateFormat = require('dateformat');
        return dateFormat(d, "ddd dd mmm yy HH:MM:ss")
    }
    const toggle = async (event) => {
        setCardExpanded(cardExpanded == 'inline' ? 'none' : 'inline')

    }

    const createTable = async () => {
        var qlist = []
        var headers = ["Date/Time"]
        // var json = JSON.parse(patientData)
        console.log(props)
        console.log('creating table', ts_min, ts_max)
        if (!props.data) {
            return
        }
        var exclude = ['ryah_ts', 'timestamp', 'user_id', 'category']
        for (var qn in props.data.keys) {
            // if (questions[qn]["category"] === props.range) {
            //     qlist.push({ question: questions[qn]["question"], id: questions[qn]["_id"] })
            if (!exclude.includes(props.data.keys[qn])) {
                headers.push(props.data.keys[qn].replace('xx', ''))
            }
            // }
        }
        console.log(headers)
        var re = {}
        var tableData = []

        // data:
        // keys: (4) ["Anxiety", "Dizziness", "Drowsiness", "Shortness of Breath"]
        // tableData: Array(10)
        // 0:
        // Anxiety: 0
        // Dizziness: 2
        // Drowsiness: 3
        // Shortness of Breath: 3
        // category: "daily"
        // ryah_ts: "2020-05-28T11:10:51-04:00"
        // timestamp: 1590678651000
        // user_id: "453"
        // __proto__: Object

        var valid = ["Anxiety", "Dizziness", "Drowsiness", "Shortness of Breath"]
        for (var a in props.data.tableData) {
            var xx = props.data.tableData[a]
            // console.log(xx)
            var ts_date = new Date(xx.timestamp)
            var ts = ts_date.toLocaleDateString('en-US', { day: 'numeric' }) + ' ' + ts_date.toLocaleDateString('en-US', { month: 'short' }) + ' ' + ts_date.toLocaleDateString('en-US', { year: 'numeric' }) + ' - ' + ("0" + ts_date.getHours()).slice(-2) + ":" + ("0" + ts_date.getMinutes()).slice(-2) + ":" + ("0" + ts_date.getSeconds()).slice(-2)
            var row = [ts]


            for (var t in props.data.keys) {
                var tt = props.data.keys[t]

                if (!exclude.includes(tt)) {
                    if (tt in xx) {
                        var cellclass = ''
                        // console.log(xx[tt])
                        if (valid.includes(tt)) {
                            if (xx[tt] >= 5 && xx[tt] <= 7) {
                                cellclass = "classes.tableCellAlertLow"
                            }
                            if (xx[tt] >= 8 && xx[tt] <= 10) {
                                cellclass = "classes.tableCellAlertHigh"
                            }
                        }

                        row.push({ 'class': cellclass, value: xx[tt].toString() })
                        // row.push(xx[tt].toString())
                    } else {
                        if (valid.includes(tt)) {
                            row.push('0')
                        } else {
                            row.push('--')
                        }

                    }
                }

            }


            tableData.push(row)
        }


        setTableHeader(headers);
        setTableData(tableData)

    }

    const handleChange = async (event, value) => {
        console.log(event)
        setWindow(value);
    }

    return (

        <div><Card>
            <CardHeader color="info" onClick={toggle}>
                <h3 className="header">Ryah Vaping stats</h3>
            </CardHeader>
            <CardBody style={{ display: cardExpanded }}>

                {/* <Slider aria-label="Range" value={window} onChange={handleChange} inputProps={{
                    step: { 1},
                    min: { 0},
                    max: { 180},
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                }} /> */}
                <Slider aria-label="Range" value={window} onChange={handleChange} max={180} inputProps={{

                    type: 'number',
                    'aria-labelledby': 'input-slider',
                }} />
                Since {date} ({window} days ago) - {totalSessions} sessions
                {/* <Table
                    tableHeaderColor="primary"
                    tableHead={tableHeader}
                    tableData={tableData}
                /> */}
            </CardBody>
        </Card>
        </div>



    )


}


export default RyahStats;