import React, { useState, createContext, useEffect } from "react";
import PatientAPI from '../aws/PatientAPI.js'

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import Table from "../components/Table/Table.js";
import Button from "../components/CustomButtons/Button.js";
import axios from "axios";
//Generic code for a RYAH results table when answers are presented in a table (e.g. weekly and daily responses)

// class RyahTable extends React.Component {
const RyahTable = (props) => {
    // constructor(props) {
    //     super(props)
    //     this.ts_min = 0;
    //     this.ts_max = Number.MAX_VALUE;
    //     this.patientapi = React.createRef();
    //     this.state = {
    //         patient_id: 0,
    //         patient_data: null,
    //         patient_fetched: false,
    //         card_expanded: 'inline',
    //         questions: null,
    //         tableData: [],
    //         tableHeader: [],
    //         range: ''
    //     };
    //     this.tableData = []
    //     this.tableHeader = []
    //     this.toggle = this.toggle.bind(this);
    //     this.createTable = this.createTable.bind(this);
    //     // this.tableData = [];
    //     // this.tableHeader = [];
    // }
    const [url, setUrl] = useState('')
    const [auth, setAuth] = useState('')
    const [questions, setQuestions] = useState([])
    const [ts_min, setTsMin] = useState(0)
    const [ts_max, setTsMax] = useState(Number.MAX_VALUE)
    const [tableHeader, setTableHeader] = useState([])
    const [tableData, setTableData] = useState([])
    const [cardExpanded, setCardExpanded] = useState('inline')
    const [patientData, setPatientData] = useState({})
    const [pos, setPos] = useState(0)
    const [data, setData] = useState(null)
    const [title, setTitle] = useState('')

    const setupTables = async (props) => {
        var now = new Date().getTime();
        var fetch_url = props.url + `/patient?action=ryah_questions`;
        // var questions = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
        var questions = [{ "id": "id1", "question": "question 1" }, { "id": "id2", "question": "question 2" }]

        setQuestions(questions)
        console.log(questions)
        var fetch_url = props.url + `/patient?action=patient_getvape&patient_id=${props.patientID}`;
        var patient_data = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
        // var patient_data = await this.patientapi.current.patient_api(`action=patient_getvape&patient_id=${this.props.patient_id}`)
        console.log(patient_data);
        if (patient_data !== '{"no_data":true}') {
            console.log('got patient data')
            setPatientData(patient_data)
        }
        // console.log(this.props.patient_data)
        createTable();
        switch (props.range) {
            case '48h':
                setTsMin(now - (1000 * 60 * 60 * 48));
                break;
            case 'week':
                setTsMin(now - (1000 * 60 * 60 * 24 * 7));
                break;
            case 'month':
                setTsMin(now - (1000 * 60 * 60 * 24 * 31));
                break;
            default:
                break;
        }
        // console.log(this.state.questions, this.props.patient_data)
        // if (this.props.range !== this.state.range && this.state.questions !== null && this.props.patient_data !== null && this.props.patient_data !== '{"no_data":true}') {
        //     if (previousState.range !== this.props.range) {
        //         this.setState({ range: this.props.range })
        //         this.createTable();
        //     }


        createTable();
        // }
    }
    useEffect(() => {
        console.log(props)
        updateDisplay(pos);
    }, [props.data]);
    useEffect(() => {
        console.log(props)
    }, []);

    const clean_date = async (d) => {
        // return d.toString().replace('GMT+0000 (Greenwich Mean Time)', '').replace('GMT+0100 (Greenwich Mean Time)', '')
        var dateFormat = require('dateformat');
        return dateFormat(d, "ddd dd mmm yy HH:MM:ss")
    }
    const toggle = async (event) => {
        setCardExpanded(cardExpanded == 'inline' ? 'none' : 'inline')

    }

    const changePrevious = async () => {
        if (pos > 0) {
            var newpos = pos - 1
            setPos(newpos)
            updateDisplay(newpos)
        }
    }
    const changeNext = async () => {
        if (pos < (props.data[props.period].tableData.length - 1)) {
            var newpos = pos + 1
            setPos(newpos)
            updateDisplay(newpos)
            console.log(pos)
        }
    }
    const updateDisplay = async (p) => {
        console.log(props.period)
        console.log(props.data)
        var s = props.data[props.period]
        console.log(s)
        var c = []
        if (!s) {
            return
        }
        var exclude = ['ryah_ts', 'timestamp', 'user_id', 'category']
        if (s.keys.includes('timestamp')) {
            var ts_date = new Date(s.tableData[p]['timestamp'])
            var ts = ts_date.toLocaleDateString('en-US', { day: 'numeric' }) + ' ' + ts_date.toLocaleDateString('en-US', { month: 'short' }) + ' ' + ts_date.toLocaleDateString('en-US', { year: 'numeric' }) + ' - ' + ("0" + ts_date.getHours()).slice(-2) + ":" + ("0" + ts_date.getMinutes()).slice(-2) + ":" + ("0" + ts_date.getSeconds()).slice(-2)
            setTitle(ts)
        }
        else if (s.keys.includes('ryah_ts')) {
            setTitle(s.tableData[p]['ryah_ts'])
            // c.push({ 'title': 'Date/time', 'content':  })
        }

        for (var key in s.keys) {
            if (!(exclude.includes(s.keys[key]))) {
                c.push({ 'title': s.keys[key], 'content': s.tableData[p][s.keys[key]] })
            }

        }
        console.log(c)
        setData(c)
    }

    const createTable = async () => {
        var qlist = []
        var headers = ["Date/Time"]
        // var json = JSON.parse(patientData)

        console.log('creating table', ts_min, ts_max)
        if (!props.data) {
            return
        }
        
        for (var qn in props.data.keys) {
            // if (questions[qn]["category"] === props.range) {
            //     qlist.push({ question: questions[qn]["question"], id: questions[qn]["_id"] })
            if (qn > 0) {
                headers.push(props.data.keys[qn])
            }

            // }
        }
        console.log(headers)
        var re = {}
        var tableData = []
        // for (var qn in questions) {
        //     if (questions[qn]["category"] === props.range) {
        //         qlist.push({ question: questions[qn]["question"], id: questions[qn]["_id"] })
        //         headers.push(questions[qn]["question"])
        //     }
        // }
        // var re = {}
        // var tableData = []
        // console.log(json)
        // for (let key in json) {
        //     for (var resp in json[key]) {
        //         if (!((json[key][resp]['ts']) in re)) {
        //             re[json[key][resp]['ts']] = {}
        //         }
        //         re[json[key][resp]['ts']][key] = json[key][resp]['resp']

        //     }
        // }
        // console.log(re)
        // for (var ts in re) {
        //     var row_valid = false;
        //     var int_ts = parseInt(ts);
        //     if (int_ts > ts_min && int_ts <= ts_max) {
        //         var row = [clean_date(new Date(int_ts))]
        //         for (var q in qlist) {
        //             if (qlist[q]["id"] in re[ts]) {
        //                 row_valid = true;
        //                 var cellclass = ''
        //                 if (re[ts][qlist[q]["id"]] >= 5) {
        //                     cellclass = "classes.tableCellAlertLow"
        //                 }
        //                 if (re[ts][qlist[q]["id"]] >= 8) {
        //                     cellclass = "classes.tableCellAlertHigh"
        //                 }
        //                 row.push({ 'class': cellclass, value: re[ts][qlist[q]["id"]] })
        //             } else {
        //                 row.push('--')
        //             }
        //         }
        //         if (row_valid) {
        //             tableData.unshift(row)
        //         }
        //     }
        // }
        setTableHeader(headers);
        setTableData(tableData)

    }



    return (

        <div><Card>
            <CardHeader color="info" onClick={toggle}>
                <h3 className="header">Questionnaire - {props.period}</h3>
            </CardHeader>
            <CardBody style={{ display: cardExpanded }}>
                {data && (
                    <>
                        {props.data[props.period].tableData.length == 0 && (
                            <h3>No entries</h3>
                        )
                        }
                        {props.data[props.period].tableData.length > 0 && (
                            <>
                                <Button type="button" color="primary" onClick={() => {
                                    changePrevious();
                                }}>previous</Button>
                                <Button type="button" color="primary" onClick={() => {
                                    changeNext();
                                }}>next</Button>

                                <h3>{pos + 1} of {props.data[props.period].tableData.length}</h3>



                                {/* <h4>prev next</h4> */}
                                <h2>{title}</h2>
                                {
                                    data.map((item) => (
                                        <p>

                                            {item.title} <b>{item.content}</b>
                                        </p>
                                    ))
                                }
                            </>
                        )
                        }
                    </>
                )}

                {/* 
                <Table
                    tableHeaderColor="primary"
                    tableHead={tableHeader}
                    tableData={tableData}
                /> */}
            </CardBody>
        </Card>
        </div>



    )


}


export default RyahTable;