import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from '../pages/App';
import Patient from '../pages/Patient';
const Root = ({ store }) => (
    <Provider store={store}>
        <Router>
            <Route path="/patient" component={Patient} />
            <Route path="/App" component={App} />
            <Route exact path="/" component={App} />

        </Router>
    </Provider>
)
Root.propTypes = {
    store: PropTypes.object.isRequired
}
export default Root