import React, { useState, createContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../components/CustomButtons/Button.js";
const Header = (props) => {

    async function handleClick() {
        // var dest = 'https://app.heydoc.co.uk/booking'
        var dest = 'https://app.semble.io/booking'
        window.open(dest, '_blank');
    }

    return (
        <Grid container spacing={3} alignItems="center">

            <Grid item xs={12} sm={9}>
                <h1><a href="/">&nbsp;&nbsp;LVL Staff Portal</a></h1>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Button type="button" color="primary" onClick={handleClick}>Go to Semble</Button>

            </Grid>

        </Grid >

    )

}

export default Header;