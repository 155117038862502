export const UPDATE_NAME = 'UPDATE_NAME';

export function updateName(name) {
    console.log('action update:' + name)
    return {
        type: UPDATE_NAME,
        app_name: name
    }
}

export const SEARCH_FIELD = 'SEARCH_FIELD';


export function searchField(name) {
    return {
        type: SEARCH_FIELD,
        app_name: name
    }
}



export const PATIENT_DATA = 'PATIENT_DATA';


export function updatePatientData(data_obj) {
    return {
        type: PATIENT_DATA,
        patient_data: data_obj
    }
}