import { UPDATE_NAME, SEARCH_FIELD, PATIENT_DATA } from "../actions"
const initialState = {
    app_name: '...'
};

export default function testApp(state = initialState, action) {
    console.log('test app something')
    console.log(action)
    switch (action.type) {
        case UPDATE_NAME:
            console.log(action.name)
            return {
                ...state,
                app_name: action.name
            };
        case SEARCH_FIELD:
            return {
                ...state,
                search_field: action.name
            }
        case PATIENT_DATA:
            return {
                ...state,
                patient_data: action.data
            }
        default:
            return state;
    }
}
