import React, { useState, createContext, useEffect } from "react";
import store from '../store';
import PatientAPI from '../aws/PatientAPI.js'
import PatientAPIEHR from '../aws/PatientAPIEHR.js'
import "../assets/css/material-dashboard-react.css?v=1.8.0";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import Table from "../components/Table/Table.js";
import Grid from "@material-ui/core/Grid";
import Button from "../components/CustomButtons/Button.js";
import axios from "axios";
import { updatePatientData } from "../actions";

import Webcam from "react-webcam";
// import {Button} from '@material-ui/core';

const WebcamComponent = () => <Webcam />;

const videoConstraints = {
    width: 1600,
    height: 1600,
    facingMode: { exact: "environment" }
};

const PatientDetails = (props) => {
    const [image, setImage] = useState('');
    const webcamRef = React.useRef(null);


    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc)
        });

    const [cardExpanded, setCardExpanded] = useState("inline")
    const [patientData, setPatientData] = useState({ data: { Items: [{ seek_id: '', patient_email: '', name: '', address: '', home_tel: '', work_tel: '', mobile: '', id: 0 }] } })
    const [fgBarcodes, setFgBarcodes] = useState('')
    const [url, setUrl] = useState('')
    const [auth, setAuth] = useState('')
    const [script_photo, setScriptPhoto] = useState(false)
    const [send_script_valid, setSendScriptValid] = useState(true)
    const [password_reset_show, setPasswordResetShow] = useState(false)
    const [account_state_show, setAccountStateShow] = useState(false)
    const [script_active, setScriptActive] = useState(false)
    const [disabled_done, setDisabledDone] = useState(false)
    const [enabled_done, setEnabledDone] = useState(false)
    const [password_done, setPasswordDone] = useState(false)
    const getPatientData = async (id) => {
        var url = props.url + "/patient?action=search_patient_all&search_str=" + id;
        console.log(props)
        console.log(props.auth)
        console.log(url)
        const r = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth } });
        console.log(r)
        var url2 = props.url + "/patient?action=fg_barcodes&patient_id=" + id;
        const r2 = await axios.get(url2, { headers: { Authorization: 'Bearer ' + props.auth } });
        console.log(r2)
        setFgBarcodes(r2.data)
        return r
        // return r.Items[0];
    }
    const updatePatientData = async () => {
        var window_url = window.location.href

        var r = await getPatientData(props.patientID)
        setPatientData(r)
        console.log('RESPONSE==>', r)
        // }
    }
    useEffect(() => {
        console.log('URL CHANGED')
        setUrl(props.url);
        updatePatientData();
    }, [props.url])
    useEffect(() => {
        console.log('AUTH CHANGED')
        setAuth(props.auth);
        updatePatientData();
    }, [props.auth])
    useEffect(() => {

    }, []);

    // async componentDidMount() {
    //     this.patientapi.current.check_patient_param()
    //     await this.patientapi.current.cognito_ensure_login()
    //     var patient_id = this.patientapi.current.get_current_patient_id();
    //     store.dispatch({ type: 'PATIENT_DATA', data: { id: patient_id } })
    //     this.setState({ ...this.state, patient_id: patient_id })
    //     console.log(patient_id);
    //     var patient_data = await this.patientapi.current.patient_api(`action=search_patient&search_str=${patient_id}`)
    //     console.log(patient_data);
    //     this.setState({ ...this.state, patient_data: patient_data })

    // }
    // constructor(props) {
    //     super(props)

    //     this.patientapi = React.createRef();
    //     this.patientapiehr = React.createRef();
    //     this.state = {
    //         patient_id: 0,
    //         patient_data:
    //             { ry_id: 0, seek_id: 0, fg_id: 0, patient_email: 0, ehr_patient: "" },
    //         contact_data:
    //             { name: "", address: "", home_tel: "", work_tel: "", mobile: "" },
    //         card_expanded: 'inline'
    //     };
    //     this.toggle = this.toggle.bind(this);
    //     this.handleClick = this.handleClick.bind(this);
    // }
    // async toggle(event) {
    //     this.setState({ card_expanded: (this.state.card_expanded === 'inline') ? 'none' : 'inline' })
    // }

    // async handleClick() {
    //     var response = await this.patientapiehr.current.patient_apiehr(`ehr_id=${this.state.patient_id}`)
    //     console.log(response)
    //     if (response.records.length > 0) {
    //         var contact_data = { name: "", address: "", home_tel: "", work_tel: "", mobile: "" }
    //         var r = response.records[0]
    //         console.log(r)

    //         if ('Name' in r) {
    //             contact_data.name = r['Name'] + ' '
    //         }
    //         if ('Surname' in r) {
    //             contact_data.name += r['Surname']
    //         }
    //         if ('Full Address' in r) {
    //             contact_data.address = r['Full Address']
    //         }
    //         if ('Home Phone Number' in r) {
    //             contact_data.home_tel = r['Home Phone Number']
    //         }
    //         if ('Work Phone Number' in r) {
    //             contact_data.work_tel = r['Work Phone Number']
    //         }
    //         if ('Mobile Phone Number' in r) {
    //             contact_data.mobile = r['Mobile Phone Number']
    //         }
    //         this.setState({ ...this.state, contact_data: contact_data })
    //     }

    // };
    async function handleClick() {
        // var dest = 'https://app.heydoc.co.uk/patient/' + patientData.data.Items[0].ehr_patient + '/summary'
        var dest = 'https://app.semble.io/patient/' + patientData.data.Items[0].ehr_patient + '/summary'
        window.open(dest, '_blank');
    }

    async function handleClickPrescription() {
        setScriptPhoto(true)
        setScriptActive(true)
    }
    async function handleClickSubmitScript() {
        setSendScriptValid(false);
        var url = props.url + "/patient?action=script&firstname=" + patientData.data.Items[0].lvl_first_name + "&lastname=" + patientData.data.Items[0].lvl_last_name + "&dob=" + patientData.data.Items[0].lvl_dob + "&heydoc_id=" + props.patientID;
        const r = await axios.post(url, image, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'image/jpeg' } });


    }
    async function handlePasswordReset() {
        setPasswordDone(true)
        console.log('PASSWORD RESET 1')
        var url = props.url + "/patient?action=reset_password&firstname=" + patientData.data.Items[0].lvl_first_name + "&lastname=" + patientData.data.Items[0].lvl_last_name + "&dob=" + patientData.data.Items[0].lvl_dob + "&heydoc_id=" + props.patientID;
        const r = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth } });
        console.log('PASSWORD RESET 2')
        console.log(r)
    }
    async function handleAccountEnable() {
        setEnabledDone(true)
        var url = props.url + "/patient?action=enable_account&firstname=" + patientData.data.Items[0].lvl_first_name + "&lastname=" + patientData.data.Items[0].lvl_last_name + "&dob=" + patientData.data.Items[0].lvl_dob + "&heydoc_id=" + props.patientID;
        const r = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth } });

    }
    async function handleAccountDisable() {
        setDisabledDone(true)
        var url = props.url + "/patient?action=disable_account&firstname=" + patientData.data.Items[0].lvl_first_name + "&lastname=" + patientData.data.Items[0].lvl_last_name + "&dob=" + patientData.data.Items[0].lvl_dob + "&heydoc_id=" + props.patientID;
        const r = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth } });

    }
    return (
        <Grid>
            <Card>
                <CardHeader color="info">
                    <h3 className="header">Patient details</h3>

                </CardHeader>
                <CardBody style={{ display: cardExpanded }}>
                    {/* <PatientAPI ref={this.patientapi} />
                    <PatientAPIEHR ref={this.patientapiehr} /> */}
                    <Table
                        tableHeaderColor="primary"
                        // tableHead={['Field', 'Data']}
                        tableData={[
                            ["EHR ID", props.patientID],
                            // ["SEEK ID", patientData.data.Items[0].seek_id],
                            // ["RYAH ID", this.state.patient_data.ry_id],
                            // ["Fitness Genes ID", this.state.patient_data.fg_id],
                            ["First name", patientData.data.Items[0].lvl_first_name],
                            ["Last name", patientData.data.Items[0].lvl_last_name],
                            ["Date of birth", patientData.data.Items[0].lvl_dob],
                            ["Email", patientData.data.Items[0].patient_email],
                            ["Fitness Genes Barcode(s)", fgBarcodes]
                        ]}
                    />

                    {/* <Table

                        tableHeaderColor="primary"
                        // tableHead={['Field', 'Data']}
                        tableData={[
                            ["Name", patientData.data.Items[0].name],
                            ["Address", patientData.data.Items[0].address],
                            ["Home Tel", patientData.data.Items[0].home_tel],
                            ["Work Tel", patientData.data.Items[0].work_tel],
                            ["Mobile", patientData.data.Items[0].mobile],

                        ]}
                    /> */}


                    <Button type="button" color="primary" onClick={handleClick}>Go to Semble patient record</Button><br />
                    <Button type="button" color="primary" onClick={handleClickPrescription}>Add Special Prescription</Button>
                    {script_active ?

                        <div className="webcam-container">
                            <div className="webcam-img">

                                {image == '' && script_photo ? <Webcam
                                    audio={false}
                                    height={320}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    width={320}
                                    minScreenshotHeight={1600}
                                    minScreenshotWidth={1600}
                                    videoConstraints={videoConstraints}
                                /> : <img src={image} height={320} width={320} />}
                            </div>
                            {script_photo == true ?
                                <div className="ImageCam">

                                    {image != '' ?
                                        <div>
                                            <Button type="button" color="primary" onClick={(e) => {
                                                e.preventDefault();
                                                setImage('')
                                                setSendScriptValid(true);
                                            }}
                                                className="webcam-btn">
                                                Retake Image</Button>
                                            {send_script_valid == true ?
                                                <Button type="button" color="primary" onClick={handleClickSubmitScript}>Send Prescription</Button> :
                                                <div>Sent</div>}

                                        </div> :
                                        <Button type="button" color="primary" onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }}
                                            className="webcam-btn">Capture</Button>
                                    }
                                </div> :
                                <div />
                            }
                        </div> :
                        <div />
                    }

                </CardBody>
            </Card >
        </Grid >

    )

}

PatientDetails.defaultProps = {
    patient_data: {
        id: 99
    }
}

export default PatientDetails;