import React, { useState, createContext, useEffect } from "react";
import Header from '../seek_components/Header';
import PatientDetails from '../seek_components/PatientDetails'
import PatientExtraSettings from '../seek_components/PatientExtraSettings'
// import FitnessGenesStatus from '../seek_components/FitnessGenesStatus'
import RyahCard from '../seek_components/RyahCard'
import RyahLink from '../seek_components/RyahLink'
import RyahCognito from '../seek_components/RyahCognito'
import Grid from "@material-ui/core/Grid";
import GridItem from "../components/Grid/GridItem.js";
// import PatientAPIEHR from '../aws/PatientAPIEHR'


import FitnessGenesStatus from "../seek_components/FitnessGenesStatus"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";

import { withAuthenticator } from '@aws-amplify/ui-react'
import awslink from '../aws/awslink.json';
Amplify.configure(awsExports);
const Patient = (props) => {
    // class Patient extends React.Component {
    // async componentDidMount() {
    //     this.patientapiehr.current.check_cognito_callback()
    //     await this.patientapiehr.current.cognito_ensure_login()
    // }

    // constructor(props) {
    //     super(props)
    //     // this.patientapiehr = React.createRef();
    //     this.setState({ signin: false });
    // }
    const [idToken, setIdToken] = useState('')
    const [patientID, setPatientID] = useState('')
    // constructor(props) {
    //   super(props)
    //   this.patientapi = React.createRef();
    //   this.setState({ signin: false });
    // }
    useEffect(() => {
        Auth.currentSession().then((res) => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            setPatientID(window.location.href
                .split('patient_id=')[1].split('&')[0])
            //You can print them to see the full objects
            console.log(res)
            console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
            console.log(`myJwt: ${jwt}`);
            setIdToken(res.getIdToken().jwtToken)
            console.log(res.getIdToken())
            console.log(idToken)

        });
    }, []);


    return (
        <div>

            {/* <PatientAPIEHR ref={this.patientapiehr} /> */}
            <Header />
            <Grid container direction="row" >
                <GridItem xs={12} sm={12} md={12}>
                    <PatientDetails url={awslink.api_patient} auth={idToken} patientID={patientID} />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12}>
                    <FitnessGenesStatus url={awslink.api_patient} auth={idToken} patientID={patientID} />
                </GridItem> */}
                {/* <GridItem xs={12} sm={12} md={12}>
                    <RyahLink url={awslink.api_patient} auth={idToken} patientID={patientID} />
                </GridItem> */}
                {/* <GridItem xs={12} sm={12} md={12}>
                    <RyahCognito url={awslink.api_patient} auth={idToken} patientID={patientID} />
                </GridItem> */}

                <GridItem xs={12} sm={12} md={12}>
                    <RyahCard url={awslink.api_patient} auth={idToken} patientID={patientID} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <PatientExtraSettings url={awslink.api_patient} auth={idToken} patientID={patientID} />
                </GridItem>



            </Grid>
        </div>
    )

}

// export default Patient;
export default withAuthenticator(Patient)