import React, { useState, createContext, useEffect } from "react";
import PatientAPI from '../aws/PatientAPI.js'

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import Chart from "react-apexcharts";
import Grid from "@material-ui/core/Grid";

import ApexCharts from 'apexcharts';
import axios from "axios";
// class RyahGraph extends React.Component {
const RyahGraph = (props) => {
    //input parameters:
    // - patient data
    // - graph(s) to render
    const [url, setUrl] = useState('')
    const [auth, setAuth] = useState('')
    const [patientData, setPatientData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [series, setSeries] = useState([])
    const [seriesData, setSeriesData] = useState([[0, 0]])

    const fetchPatient = async () => {
        // console.log(this.props.patient_id)
        // console.log(this.patientapi)



        // var now = new Date().getTime();
        // var fetch_url = props.url + `/patient?action=patient_getvape&patient_id=${props.patientID}`;
        // var patient_data = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
        // // var patient_data = await this.patientapi.current.patient_api(`action=patient_getvape&patient_id=${this.props.patient_id}`)
        // console.log(patient_data);
        // if (patient_data !== '{"no_data":true}') {
        //     console.log('got patient data')
        //     setPatientData(patient_data)
        //     // this.setState({ patient_data: patient_data, patientFetched: true })
        //     createSeries();
        //     ApexCharts.exec(this.props.qlabel, 'updateOptions', {
        //         xaxis: {
        //             min: now - (1000 * 60 * 60 * 48),
        //             max: now,
        //         }
        //     })
        // } else {
        //     console.log('not able to fetch yet')
        // }
    }
    const setupChart = async () => {
        setSeries([
            {
                name: props.qlabel,
                data: props.graphData[props.qlabel]
                // data: [0, 1, 3]
            }
        ])
        console.log(props)
        // console.log(props.qlabel)
        console.log(props.qlabel, props.graphData[props.qlabel])
    }
    useEffect(() => {
        console.log('GRAPHDATA CHANGED')

        setupChart()
        // changeTimeRange(props.range)
    }, [props.graphData])
    // useEffect(() => {
    //     console.log('URL CHANGED')
    //     setUrl(props.url);
    //     fetchPatient();
    //     setupChart()
    //     // changeTimeRange(props.range)
    // }, [props.url])
    // useEffect(() => {
    //     console.log('AUTH CHANGED')
    //     setAuth(props.auth);
    //     fetchPatient();
    //     setupChart()
    //     // setSeries([
    //     //     {
    //     //         name: props.qlabel,
    //     //         data: seriesData
    //     //         // data: [0, 1, 3]
    //     //     }
    //     // ])
    //     // changeTimeRange(props.range)
    // }, [props.auth])
    useEffect(() => {
        console.log(props)
        setupChart()
        // changeTimeRange(props.range)
    }, []);
    // useEffect(() => {
    //     setSeries([
    //         {
    //             name: props.qlabel,
    //             data: seriesData
    //             // data: [0, 1, 3]
    //         }
    //     ])
    // }, [seriesData]);

    const createSeries = (props) => {
        var json = JSON.parse(patientData)
        console.log(json)
        var qcd = []
        var qid = props.qmap;
        console.log(props.qlabel, qid);
        for (var rec in json[qid]) {
            var ts = json[qid][rec]['ts']
            // var date = new Date(ts)
            // var year = date.getFullYear()
            // var month = date.getMonth() + 1
            // var day = date.getDate()
            // var hours = date.getHours();
            // var minutes = date.getMinutes();
            // var seconds = date.getSeconds();
            // var dstr = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
            qcd.push([ts, json[qid][rec]['resp']])
        }
        setSeriesData(qcd)

    }
    // constructor(props) {
    //     super(props);
    //     this.fetchPatient = this.fetchPatient.bind(this);
    //     this.changeTimeRange = this.changeTimeRange.bind(this);
    //     this.patientapi = React.createRef();
    //     this.series = [[0, 0], [10, 10]];

    //     this.state = {
    //         patient_id: 0,
    //         patient_data: null,
    //         patient_fetched: false,
    //         card_expanded: 'inline',
    //         range: '48h',
    //         series_data: [[0, 0]],

    //         chart_options:
    //     },
    // }


    const changeTimeRange = async (range) => {
        var now = new Date().getTime()
        switch (range) {

            case 'week':
                ApexCharts.exec(props.qlabel, 'updateOptions', {
                    xaxis: {
                        min: now - (1000 * 60 * 60 * 24 * 7),
                        max: now,
                    }
                })
                break;
            case 'month':
                ApexCharts.exec(props.qlabel, 'updateOptions', {
                    xaxis: {
                        min: now - (1000 * 60 * 60 * 24 * 31),
                        max: now,
                    }
                })
                break;
            case 'all':

                ApexCharts.exec(props.qlabel, 'updateOptions', {
                    xaxis: {
                        min: undefined,
                        max: undefined,
                    }
                })
                break;
            case '48h':
            default:
                ApexCharts.exec(props.qlabel, 'updateOptions', {
                    xaxis: {
                        min: now - (1000 * 60 * 60 * 48),
                        max: now,
                    }
                })

                break;
        }
        // if (updatestate) {
        //     this.setState({ ...this.state, range: range })
        // }

    }


    // async componentDidMount() {

    //     if (!this.state.patientFetched && this.props.patient_id != null) {
    //         this.fetchPatient();
    //         this.changeTimeRange(this.state.range);

    //     }

    // }
    // async componentDidUpdate() {
    //     if (!this.state.patientFetched && this.props.patient_id != null) {

    //         this.fetchPatient();
    //     }
    // }


    // render = (props) => {

    //     var series = [
    //         {
    //             name: this.props.qlabel,
    //             data: this.state.series_data
    //             // data: [0, 1, 3]
    //         }
    //     ]
    var opts = {
        chart: {
            height: 240,
            id: props.qlabel,
            // group: 'vape'
            animations: {
                enabled: false
            }
        },
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            },
        },
        tooltip: {
            followCursor: false,
            theme: 'dark',
            enabled: true,
            x: {
                show: true,
                format: 'dd MMM yyyy HH:mm:ss'
            },

        },
        yaxis: {
            labels: {
                minWidth: 40
            },
            min: 0,
            max: 10
        },
        annotations: {
            yaxis: [
                {
                    y: 6,
                    borderColor: "#ff0000",
                    label: {
                        borderColor: "#",
                        style: {
                            color: "#fff",
                            background: "#ff0000"
                        },
                        text: "Above 6"
                    }
                }
            ]

        }
    }

    const valid = ["Anxiety", "Dizziness", "Drowsiness", "Shortness of Breath"]
    // console.log('CHECKING>>>', props.qlabel)
    if (valid.includes(props.qlabel)) {
        return (

            <Grid>

                <h3>{props.qlabel}</h3>
                <Chart options={opts}
                    series={series} type="line"
                    height={160}
                    range={props.range}>
                </Chart>

                {/* <Button type="button" color="primary" onClick={() => this.changeTimeRange('48h', true)}>48h</Button>
            <Button type="button" color="primary" onClick={() => this.changeTimeRange('week', true)}>week</Button>
            <Button type="button" color="primary" onClick={() => this.changeTimeRange('month', true)}>month</Button>
            <Button type="button" color="primary" onClick={() => this.changeTimeRange('all', true)}>All</Button> */}
            </Grid>

        )
    }
    else {
        return (
            <>
            </>
        )
    }



}

export default RyahGraph;