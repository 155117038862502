import React from 'react';
import Root from './seek_components/Root'
import { render } from 'react-dom'
import './index.css';
import * as serviceWorker from './serviceWorker';


import store from './store';

// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
render(<Root store={store} />, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
