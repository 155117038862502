import React, { useState, createContext, useEffect } from "react";
import PatientAPI from '../aws/PatientAPI'
import Header from '../seek_components/Header';
import PatientSearch from '../seek_components/PatientSearch';
import LinkEHRPatient from '../seek_components/LinkEHRPatient';
import Grid from "@material-ui/core/Grid";
import GridItem from "../components/Grid/GridItem.js";
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import MDTReady from '../seek_components/MDTReady';

import { withAuthenticator } from '@aws-amplify/ui-react'
import awslink from '../aws/awslink.json';
Amplify.configure(awsExports);
// class App extends React.Component {
const App = (props) => {

  const [idToken, setIdToken] = useState('')
  // constructor(props) {
  //   super(props)
  //   this.patientapi = React.createRef();
  //   this.setState({ signin: false });
  // }
  useEffect(() => {
    Auth.currentSession().then((res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      //You can print them to see the full objects
      console.log(res)
      console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
      console.log(`myJwt: ${jwt}`);
      setIdToken(res.getIdToken().jwtToken)
      console.log(res.getIdToken())
      console.log(idToken)

    });
  }, []);
  // render = (props) => {
  return (
    <div className="App">
      <div>
        {/* <PatientAPI url={awslink.api_patient} idToken={idToken} /> */}
        <Header />
        <Grid container direction="row" >
          <GridItem xs={12} sm={12} md={12}>
            <MDTReady url={awslink.api_patient} auth={idToken} />
            <PatientSearch url={awslink.api_patient} auth={idToken} />
          </GridItem>
        </Grid>
        {/* <LinkEHRPatient url={awslink.api_patient} auth={idToken} /> */}
      </div>
    </div >
  )
  // }
}
export default withAuthenticator(App)
// export default App;
