import React, { useState, createContext, useEffect } from "react";
import PatientAPI from '../aws/PatientAPI.js'

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import Table from "../components/Table/Table.js";
import axios from "axios";
//Generic code for a RYAH results table when answers are presented in a table (e.g. weekly and daily responses)

// class RyahTable extends React.Component {
const RyahDaily = (props) => {
    // constructor(props) {
    //     super(props)
    //     this.ts_min = 0;
    //     this.ts_max = Number.MAX_VALUE;
    //     this.patientapi = React.createRef();
    //     this.state = {
    //         patient_id: 0,
    //         patient_data: null,
    //         patient_fetched: false,
    //         card_expanded: 'inline',
    //         questions: null,
    //         tableData: [],
    //         tableHeader: [],
    //         range: ''
    //     };
    //     this.tableData = []
    //     this.tableHeader = []
    //     this.toggle = this.toggle.bind(this);
    //     this.createTable = this.createTable.bind(this);
    //     // this.tableData = [];
    //     // this.tableHeader = [];
    // }
    const [url, setUrl] = useState('')
    const [auth, setAuth] = useState('')
    const [questions, setQuestions] = useState([])
    const [ts_min, setTsMin] = useState(0)
    const [ts_max, setTsMax] = useState(Number.MAX_VALUE)
    const [tableHeader, setTableHeader] = useState([])
    const [tableData, setTableData] = useState([])
    const [cardExpanded, setCardExpanded] = useState('inline')
    const [patientData, setPatientData] = useState({})

    // const setupTables = async (props) => {
    //     var now = new Date().getTime();
    //     var fetch_url = props.url + `/patient?action=ryah_questions`;
    //     // var questions = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
    //     var questions = [{ "id": "id1", "question": "question 1" }, { "id": "id2", "question": "question 2" }]

    //     setQuestions(questions)
    //     console.log(questions)
    //     var fetch_url = props.url + `/patient?action=patient_getvape&patient_id=${props.patientID}`;
    //     var patient_data = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
    //     // var patient_data = await this.patientapi.current.patient_api(`action=patient_getvape&patient_id=${this.props.patient_id}`)
    //     console.log(patient_data);
    //     if (patient_data !== '{"no_data":true}') {
    //         console.log('got patient data')
    //         setPatientData(patient_data)
    //     }
    //     // console.log(this.props.patient_data)
    //     createTable();
    //     switch (props.range) {
    //         case '48h':
    //             setTsMin(now - (1000 * 60 * 60 * 48));
    //             break;
    //         case 'week':
    //             setTsMin(now - (1000 * 60 * 60 * 24 * 7));
    //             break;
    //         case 'month':
    //             setTsMin(now - (1000 * 60 * 60 * 24 * 31));
    //             break;
    //         default:
    //             break;
    //     }
    //     // console.log(this.state.questions, this.props.patient_data)
    //     // if (this.props.range !== this.state.range && this.state.questions !== null && this.props.patient_data !== null && this.props.patient_data !== '{"no_data":true}') {
    //     //     if (previousState.range !== this.props.range) {
    //     //         this.setState({ range: this.props.range })
    //     //         this.createTable();
    //     //     }


    //     createTable();
    //     // }
    // }

    useEffect(() => {
        console.log(props)
        createTable();
    }, [props.data]);
    useEffect(() => {
        console.log(props)
    }, []);


    const clean_date = async (d) => {
        // return d.toString().replace('GMT+0000 (Greenwich Mean Time)', '').replace('GMT+0100 (Greenwich Mean Time)', '')
        var dateFormat = require('dateformat');
        return dateFormat(d, "ddd dd mmm yy HH:MM:ss")
    }
    const toggle = async (event) => {
        setCardExpanded(cardExpanded == 'inline' ? 'none' : 'inline')

    }

    const createTable = async () => {
        var qlist = []
        var headers = ["Date/Time"]
        // var json = JSON.parse(patientData)
        console.log(props)
        console.log('creating table', ts_min, ts_max)
        if (!props.data) {
            return
        }
        var exclude = ['ryah_ts', 'timestamp', 'user_id', 'category']
        for (var qn in props.data.keys) {
            // if (questions[qn]["category"] === props.range) {
            //     qlist.push({ question: questions[qn]["question"], id: questions[qn]["_id"] })
            if (!exclude.includes(props.data.keys[qn])) {
                headers.push(props.data.keys[qn].replace('xx', ''))
            }
            // }
        }
        console.log(headers)
        var re = {}
        var tableData = []

        // data:
        // keys: (4) ["Anxiety", "Dizziness", "Drowsiness", "Shortness of Breath"]
        // tableData: Array(10)
        // 0:
        // Anxiety: 0
        // Dizziness: 2
        // Drowsiness: 3
        // Shortness of Breath: 3
        // category: "daily"
        // ryah_ts: "2020-05-28T11:10:51-04:00"
        // timestamp: 1590678651000
        // user_id: "453"
        // __proto__: Object

        var valid = ["Anxiety", "Dizziness", "Drowsiness", "Shortness of Breath"]
        for (var a in props.data.tableData) {
            var xx = props.data.tableData[a]
            // console.log(xx)
            var ts_date = new Date(xx.timestamp)
            var ts = ts_date.toLocaleDateString('en-US', { day: 'numeric' }) + ' ' + ts_date.toLocaleDateString('en-US', { month: 'short' }) + ' ' + ts_date.toLocaleDateString('en-US', { year: 'numeric' }) + ' - ' + ("0" + ts_date.getHours()).slice(-2) + ":" + ("0" + ts_date.getMinutes()).slice(-2) + ":" + ("0" + ts_date.getSeconds()).slice(-2)
            var row = [ts]


            for (var t in props.data.keys) {
                var tt = props.data.keys[t]

                if (!exclude.includes(tt)) {
                    if (tt in xx) {
                        var cellclass = ''
                        // console.log(xx[tt])
                        if (valid.includes(tt)) {
                            if (xx[tt] >= 5 && xx[tt] <= 7) {
                                cellclass = "classes.tableCellAlertLow"
                            }
                            if (xx[tt] >= 8 && xx[tt] <= 10) {
                                cellclass = "classes.tableCellAlertHigh"
                            }
                        }

                        row.push({ 'class': cellclass, value: xx[tt].toString() })
                        // row.push(xx[tt].toString())
                    } else {
                        if (valid.includes(tt)) {
                            row.push('0')
                        } else {
                            row.push('--')
                        }

                    }
                }

            }


            tableData.push(row)
        }


        setTableHeader(headers);
        setTableData(tableData)

    }



    return (

        <div><Card>
            <CardHeader color="info" onClick={toggle}>
                <h3 className="header">Daily vaping Questionnaire - {props.range}</h3>
            </CardHeader>
            <CardBody style={{ display: cardExpanded }}>

                <Table
                    tableHeaderColor="primary"
                    tableHead={tableHeader}
                    tableData={tableData}
                />
            </CardBody>
        </Card>
        </div>



    )


}


export default RyahDaily;