import React from 'react';
import PatientAPI from '../aws/PatientAPI.js'
class FitnessGenesAPI extends React.Component {

    constructor(props) {
        super(props)
        this.patientapi = React.createRef();
    }
    async fg_addbarcode(patient_id, fg_barcode) {
        var response = await this.patientapi.current.patient_api(`action=link_fg_barcode&ehr_patient=${patient_id}&fg_barcode=${fg_barcode}`)
        console.log(response)
        if (response !== 'OK') {
            return response
        }

        //TODO: check for errors
        return true;
    }

    async fg_getbarcodes(patient_id) {

        var response = await this.patientapi.current.patient_api(`action=search_patient&search_str=${patient_id}`);
        console.log(response)
        //TODO: check for errors
        return response['fg_barcodes'];
    }

    render() {
        return (
            <PatientAPI ref={this.patientapi} />
        )
    }
}

export default FitnessGenesAPI;
