import React, { useState, createContext, useEffect } from "react";
import PatientAPI from '../aws/PatientAPI.js'

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import Card from "../components/Card/Card.js";
import RyahTable from "./RyahTable.js";
// import RyahTableQuarterly from "./RyahTableQuarterly.js";
import GridItem from "../components/Grid/GridItem.js";
import axios from "axios";
const RyahTables = (props) => {
    // constructor(props) {
    //     super(props)

    //     this.patientapi = React.createRef();
    //     this.state = {
    //         patient_id: 0,
    //         patient_data: null,
    //         patient_fetched: false,
    //         card_expanded: 'inline'
    //     };
    //     this.fetchPatient = this.fetchPatient.bind(this);
    // }
    const [patientData, setPatientData] = useState(null)
    const [url, setUrl] = useState('')
    const [auth, setAuth] = useState('')
    // async componentDidMount() {
    //     console.log(this.props.patient_id)

    // }

    // async componentDidUpdate() {
    //     if (!this.state.patientFetched && props.patientID != null) {
    //         this.fetchPatient();
    //     }
    // }

    // const fetchPatient = async () => {
    //     var fetch_url = props.url + `/patient?action=patient_getvape&patient_id=${props.patientID}`
    //     var patient_data = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
    //     console.log(patient_data);
    //     setPatientData(patient_data)
    //     // this.setState({ patient_data: patient_data, patientFetched: true })

    // }
    useEffect(() => {
        console.log('URL CHANGED')
        setUrl(props.url);
        // fetchPatient();
    }, [props.url])
    useEffect(() => {
        console.log('AUTH CHANGED')
        setAuth(props.auth);
        // fetchPatient();
    }, [props.auth])
    useEffect(() => {

    }, []);


    return (
        // <Grid container>
        //     <PatientAPI ref={this.patientapi} />

        //     <GridItem xs={12} sm={12} md={12}>
        //         <RyahTable patient_data={this.state.patient_data} patient_id={this.props.patient_id} period="daily" />
        //     </GridItem>
        //     <GridItem xs={12} sm={12} md={12}>
        //         <RyahTable patient_data={this.state.patient_data} patient_id={this.props.patient_id} period="weekly" />
        //     </GridItem>
        //     <GridItem xs={12} sm={12} md={12}>
        //         <RyahTableQuarterly patient_data={this.state.patient_data} patient_id={this.props.patient_id} />
        //     </GridItem>
        // </Grid>
        <>
            <Card>
                <RyahTable data={props.data} patient_data={patientData} patient_id={props.patientID} range={props.range} period="baseline" />
            </Card>
            <Card>
                <RyahTable data={props.data} patient_data={patientData} patient_id={props.patientID} range={props.range} period="weekly" />
            </Card>
            <Card>
                <RyahTable data={props.data} patient_data={patientData} patient_id={props.patientID} range={props.range} period="quarterly" />
            </Card>

        </>





    )


}

export default RyahTables;