/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.



// prod:
const awsmobile = {

    "aws_project_region": "eu-west-2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_nGBcpmf0y",
    "aws_user_pools_web_client_id": "7ifeiarpaaq25qasnh6a0p65b6",
    "oauth": {
        "domain": "prod-global-lvl-prod-users.auth.eu-west-2.amazoncognito.com"
    }
};



export default awsmobile;
