import React, { useState, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import store from '../store';
import PatientAPI from '../aws/PatientAPI.js'
import Button from "../components/CustomButtons/Button.js";

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import CustomInput from "../components/CustomInput/CustomInput.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Table2 from "../components/Table/Table2.js";
import { DataGrid } from '@material-ui/data-grid';
// import { withStyles } from '@material-ui/core';
import awslink from '../aws/awslink.json';
import axios from "axios";
const PatientSearch = (props) => {
    const history = useHistory();
    const [patientSearch, updatePatientSearch] = useState("");
    const [tableData, setTableData] = useState([])
    const [msgState, setMsgState] = useState(false)
    const [gridData, setGridData] = useState([])
    // constructor(props) {
    //     super(props);
    //     this.state = { searchField: '', open: false, tableData: [] };
    //     // this.patientapi = React.createRef();
    //     this.handleSearchChange = this.handleSearchChange.bind(this);
    //     this.handleSearch = this.handleSearch.bind(this);
    //     this.handleSearchTableClick = this.handleSearchTableClick.bind(this);
    // }
    useEffect(() => {
        handleSearch(props, '')
        // changeTimeRange(props.range)
    }, [props.url, props.auth]);
    async function handleSearchChange(event) {
        updatePatientSearch(event.target.value);
    }

    async function handleSearch(props, search) {

        console.log(patientSearch)
        console.log(props)
        var url = props.url + "/patient?action=search_patient_all&search_str=" + patientSearch;
        const r = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth } });
        console.log(r)
        const response = r.data
        if (response['Count'] === 0) {
            setMsgState(true)

        }
        else if (response['Count'] === 1) {
            window.location = `Patient?patient_id=${response.Items[0]['ehr_patient']}`
        } else {
            var results = []
            var sortable = []
            let items = response.Items
            items.sort(function (a, b) { return b.lvl_last_ryah_ts - a.lvl_last_ryah_ts })
            console.log('ITEMS', items)
            for (let res in items) {
                var item = items[res]
                results.push([
                    { 'id': item['ehr_patient'], 'value': item['lvl_id'] },
                    { 'id': item['ehr_patient'], 'value': item['lvl_first_name'] },
                    { 'id': item['ehr_patient'], 'value': item['lvl_last_name'] },
                    { 'id': item['ehr_patient'], 'value': item['patient_email'] },
                    { 'id': item['ehr_patient'], 'value': item['ehr_patient'] },
                    { 'id': item['ehr_patient'], 'value': item['lvl_last_ryah_ts_str'] },
                ])
                sortable.push({
                    'id': item['lvl_id'],
                    'firstName': item['lvl_first_name'],
                    'surname': item['lvl_last_name'],
                    'email': item['patient_email'],
                    'ehr_patient': item['ehr_patient'],
                    'last_ryah_ts': item['lvl_last_ryah_ts_str']
                })
                console.log(item)
            }
            setTableData(results)
            console.log(sortable)
            setGridData(sortable)
        }
    }


    async function handleSearchTableClick(row, col) {
        console.log(row, col)
    }

    async function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setMsgState(false)
    }

    const routePatient = (id) => {
        var path = `/Patient?patient_id=${id}`
        history.push(path)
    }

    const grid_columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'surname', headerName: 'Surname', width: 150 },
        { field: 'email', headerName: 'Email', width: 250 },
        // { field: 'ehr_patient', headerName: 'EHR Patient', width: 100 },
        { field: 'last_ryah_ts', headerName: 'Last RYAH event', width: 200 },
    ]

    const [sortModel, setSortModel] = useState([
        {
            field: "last_ryah_ts",
            sort: "desc"
        }
    ]);
    //https://stackoverflow.com/questions/69004286/material-ui-data-grid-onsortmodelchange-causing-an-infinite-loop
    const handleSortChange = (model: GridSortModel) => {
        /* if statement to prevent the infinite loop by confirming model is 
         different than the current sortModel state */
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
        }
    };
    return (
        <div>
            <Card>
                <CardHeader color="warning">
                    <h3 className="header">Patient search</h3>

                </CardHeader>
                <CardBody>
                    <form onSubmit={e => { e.preventDefault(); handleSearch(props, e) }}>

                        <label>
                            <CustomInput
                                labelText="Search"
                                id="search-string"
                                inputProps={{
                                    onChange: handleSearchChange
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                value={patientSearch}

                            />
                        </label>
                        <Button type="button" color="primary" onClick={() => {
                            handleSearch(props, patientSearch);
                        }}>SEARCH</Button>

                    </ form>
                    {/* <Table2
                        // onClick={(cellData) => { window.location = `Patient?patient_id=${cellData.newprop.id}` }}
                        onClick={(cellData) => { routePatient(cellData.newprop.id) }}
                        tableHeaderColor="primary"
                        // tableHead={["EHR ID", "Fitness Genes ID", "RYAH ID", "Seek ID", "email"]}
                        tableHead={["LVL ID", "First name", "Last name", "Email", "EHR ID", "Last RYAH event"]}
                        tableData={tableData}

                    /> */}
                    <div style={{ height: 800, width: '100%' }}>
                        <DataGrid
                            rows={gridData}
                            columns={grid_columns}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            onCellClick={(e) => {
                                console.log(e);
                                window.location = `Patient?patient_id=${e.row.ehr_patient}`
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(model) => handleSortChange(model)}
                        // checkboxSelection
                        // disableSelectionOnClick
                        />
                    </div>
                </CardBody>
            </Card>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={msgState}
                autoHideDuration={10000}
                onClose={handleClose}
                message="No results found for search"
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );

};


export default PatientSearch;
