import React, { useState, createContext, useEffect } from "react";
import PatientAPI from '../aws/PatientAPI.js'

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import RyahTables from './RyahTables'
import RyahGraph from './RyahGraph'
import RyahDaily from './RyahDaily'
import RyahStats from './RyahStats'
import Grid from "@material-ui/core/Grid";

import Button from "../components/CustomButtons/Button.js";
import GridItem from "../components/Grid/GridItem.js";
import axios from "axios";
// class RyahCard extends React.Component {
const RyahCard = (props) => {
    // constructor(props) {
    //     super(props)

    //     this.patientapi = React.createRef();
    //     this.fgapi = React.createRef();
    //     //todo: get rid of the graphs child reference and use redux
    //     this.state = { searchField: '', questions: '', patient_id: '', fetched_graph: false, range: '48h', graphs: [] };

    //     this.updatecharts = this.updatecharts.bind(this);
    //     this.charts = '';

    //     // this.handleSearchChange = this.handleSearchChange.bind(this);
    //     // this.handleSearch = this.handleSearch.bind(this);

    // }
    const [graphsQuestions, setGraphsQuestions] = useState([])
    const [graphExpanded, setGraphExpanded] = useState('inline')
    const [range, setRange] = useState('48h')
    const [url, setUrl] = useState('')
    const [auth, setAuth] = useState('')
    const [graphsPatientData, setGraphsPatientData] = useState([{ x: 0, y: 10 }])
    const [ryahData, setRyahData] = useState({})
    const updateQuestionnaireData = async () => {
        var fetch_url = props.url + `/patient?action=ryah_questions`;
        // var questions = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
        var fetch_url = props.url + `/patient?action=patient_getvape&patient_id=${props.patientID}`;
        console.log(fetch_url)
        var patient_data = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth } });
        console.log(patient_data)
        console.log(patient_data.data)
        // console.log((patient_data.data.length())
        if (patient_data.data != null) {
            setRyahData(patient_data.data)

            setGraphsPatientData(patient_data.data.graphs.graphData)
            console.log(patient_data.data.graphs.graphData)
            // var questions = [{ "id": "id1", "question": "question 1" }, { "id": "id2", "question": "question 2" }]
            setGraphsQuestions(patient_data.data.graphs.keys)
            console.log(patient_data.data.graphs.keys)

            // var patient_id = await this.patientapi.current.get_current_patient_id();
            // console.log(patient_id)
            // this.setState({ patient_id: patient_id })
            updatecharts('all')
        }

    }
    useEffect(() => {
        console.log('URL CHANGED')
        setUrl(props.url);
        updateQuestionnaireData();
    }, [props.url])
    useEffect(() => {
        console.log('AUTH CHANGED')
        setAuth(props.auth);
        updateQuestionnaireData();
    }, [props.auth])
    useEffect(() => {

    }, []);


    const updatecharts = async (period) => {
        console.log(period)
        // console.log(this.state.graphs)
        // for (var graph in this.state.graphs) {
        //     console.log(graph)
        //     this.state.graphs[graph].changeTimeRange(period, true)
        // }
        setRange(period)
    }
    const togglegraphs = async (event) => {
        setGraphExpanded(graphExpanded === 'none' ? 'inline' : 'none')
    }
    // render = (props) => {
    //     var qlist = []
    //     var headers = []

    //     for (var qn in this.state.questions) {
    //         if (this.state.questions[qn]["category"] === 'daily') {
    //             qlist.push({ question: this.state.questions[qn]["question"], id: this.state.questions[qn]["_id"] })
    //             headers.push(this.state.questions[qn]["question"])
    //         }
    //     }
    //     if (this.state.questions !== null && this.state.patient_id !== '' && this.state.fetched_graph === false) {
    //         // this.charts = qlist.map(function (question) {
    //         //     console.log(this.state.patient_id)
    //         //     return <RyahGraph patient_id={props.PatientID}
    //         //         qmap={question.id}
    //         //         qlabel={question.question}
    //         //         range={this.state.range}
    //         //         ref={(ref) => this.state.graphs.push(ref)} />
    //         // }, this)
    //         // console.log(this.charts)
    //         // console.log(this.state.graphs)
    //         // this.setState({ fetched_graph: true })
    //     }
    // }
    return (



        <Grid container>


            {/* <Card>
                <CardHeader color="info">
                    <h3 className="header">Vaping data</h3>
                </CardHeader>
                <CardBody>

                    Limit data to:<br />
                    <Button type="button" color="primary" onClick={() => updatecharts('48h')}>48h</Button>
                    <Button type="button" color="primary" onClick={() => updatecharts('week')}>week</Button>
                    <Button type="button" color="primary" onClick={() => updatecharts('month')}>month</Button>
                    <Button type="button" color="primary" onClick={() => updatecharts('all')}>All</Button>
                </CardBody>
            </Card> */}


            <Card>
                <CardHeader color="info" onClick={togglegraphs}>
                    <h3 className="header">Daily questionnaire graphs</h3>
                </CardHeader>
                <CardBody style={{ display: graphExpanded }}>

                    {graphsQuestions.map((question, key) => (
                        <>
                            <RyahGraph patient_id={props.PatientID}
                                qmap={question.id}
                                qlabel={question.question}
                                range={range}
                                patientID={props.patientID}
                                graphData={graphsPatientData}
                            //  ref={(ref) => this.state.graphs.push(ref)}
                            />
                        </>
                    ))}

                </CardBody>
            </Card>
            <Card>
                <RyahStats patientID={props.patientID} range={range} data={ryahData.daily} />
            </Card>
            <Card>
                <RyahDaily patientID={props.patientID} range={range} data={ryahData.daily} />
            </Card>


            <RyahTables patientID={props.patientID} data={ryahData} />


        </Grid>



    )
    // return (
    //     <div>

    //     </div>
    // );


};

export default RyahCard;