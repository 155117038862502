import React, { useState, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import store from '../store';
import PatientAPI from '../aws/PatientAPI.js'
import Button from "../components/CustomButtons/Button.js";

import "../assets/css/material-dashboard-react.css?v=1.8.0";
import CustomInput from "../components/CustomInput/CustomInput.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Table2 from "../components/Table/Table2.js";
// import { withStyles } from '@material-ui/core';
import awslink from '../aws/awslink.json';
import axios from "axios";
const PatientSearch = (props) => {
    const history = useHistory();
    const [patientSearch, updatePatientSearch] = useState("");
    const [tableData, setTableData] = useState([])
    const [msgState, setMsgState] = useState(false)
    // constructor(props) {
    //     super(props);
    //     this.state = { searchField: '', open: false, tableData: [] };
    //     // this.patientapi = React.createRef();
    //     this.handleSearchChange = this.handleSearchChange.bind(this);
    //     this.handleSearch = this.handleSearch.bind(this);
    //     this.handleSearchTableClick = this.handleSearchTableClick.bind(this);
    // }
    async function fetchMDTready() {
        var url = props.url + "/patient?action=mdtready";
        const r = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth } });
        console.log(r)
        const response = r.data
        console.log(response)
        var d = []
        for (let r in response) {
            var i = response[r]
            d.push([{ 'id': i[2], 'value': i[0] }, { 'id': i[2], 'value': i[1] }])
        }
        setTableData(d)
    }
    useEffect(() => {
        // handleSearch(props, '')
        // changeTimeRange(props.range)
        fetchMDTready()


    }, [props.url, props.auth]);
    // useEffect(async () => {
    //     //fetch MDT ready at start

    // }, []);
    async function handleSearchChange(event) {
        updatePatientSearch(event.target.value);
    }

    async function handleSearch(props, search) {

        console.log(patientSearch)
        console.log(props)
        var url = props.url + "/patient?action=search_patient_all&search_str=" + patientSearch;
        const r = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth } });
        console.log(r)
        const response = r.data
        if (response['Count'] === 0) {
            setMsgState(true)

        }
        else if (response['Count'] === 1) {
            window.location = `Patient?patient_id=${response.Items[0]['ehr_patient']}`
        } else {
            var results = []
            for (let res in response.Items) {
                var item = response.Items[res]
                results.push([
                    { 'id': item['ehr_patient'], 'value': item['lvl_id'] },
                    { 'id': item['ehr_patient'], 'value': item['lvl_first_name'] },
                    { 'id': item['ehr_patient'], 'value': item['lvl_last_name'] },
                    { 'id': item['ehr_patient'], 'value': item['patient_email'] },
                    { 'id': item['ehr_patient'], 'value': item['ehr_patient'] },])
                console.log(item)
            }
            setTableData(results)
        }
    }


    async function handleSearchTableClick(row, col) {
        console.log(row, col)
    }

    async function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setMsgState(false)
    }

    const routePatient = (id) => {
        // var path = `https://app.heydoc.co.uk/patient/${id}/summary`
        var path = `https://app.semble.io/patient/${id}/summary`
        window.open(path, '_blank');
    }

    return (
        <div>
            <Card>
                <CardHeader color="warning">
                    <h3 className="header">Patients labelled MDT Ready</h3>

                </CardHeader>
                <CardBody>
                    {/* <form onSubmit={e => { e.preventDefault(); handleSearch(props, e) }}>

                        <label>
                            <CustomInput
                                labelText="Search"
                                id="search-string"
                                inputProps={{
                                    onChange: handleSearchChange
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                value={patientSearch}

                            />
                        </label>
                        <Button type="button" color="primary" onClick={() => {
                            handleSearch(props, patientSearch);
                        }}>SEARCH</Button>

                    </ form>
                    */}
                    <Table2
                        // onClick={(cellData) => { window.location = `Patient?patient_id=${cellData.newprop.id}` }}
                        onClick={(cellData) => { routePatient(cellData.newprop.id) }}
                        tableHeaderColor="primary"
                        // tableHead={["EHR ID", "Fitness Genes ID", "RYAH ID", "Seek ID", "email"]}
                        tableHead={["Name", "Updated"]}
                        tableData={tableData}

                    />
                </CardBody>
            </Card>

        </div>
    );

};


export default PatientSearch;
